import { calculateTimeDifferenceInHours } from '@/common/utils';
import { readNewSettings } from '@/api/user';
import BaseIndexedDB from '@/db/BaseIndexedDB';

const DB_INFO = {
  name: 'User',
  objectStoreName: 'user',
  keys: ['user', 'extensions'],
  schema: { user: '' },
  version: 1
};

const CACHE_TIMESTAMP_KEY = 'VBCUserService.cacheTimestamp';
const CACHE_TTL_HOURS = 12;

export type VBCUser = {
  id: string;
  accountId: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  locale: string;
};

export type VBCExtension = {
  id: string;
  extension: string;
  didList: Array<string>;
  isDefault: boolean;
  isVirtual: boolean;
  locale: string;
};

export default class VBCUserService {
  private readonly useCache: boolean;
  private readonly db: BaseIndexedDB;
  constructor(options = { useCache: true }) {
    this.useCache = options.useCache;
    this.db = new BaseIndexedDB(DB_INFO.name, DB_INFO.version, DB_INFO.schema);
  }

  public async getUser(): Promise<{ user: VBCUser; extensions: Array<VBCExtension> }> {
    if (this.useCache) {
      // Check if the cache TTL has passed. If it wasn't then try to fetch the data from the cache.
      const cacheTimestamp = JSON.parse(localStorage.getItem(CACHE_TIMESTAMP_KEY) || '0');
      const cacheTimestampDiffInHours = calculateTimeDifferenceInHours(cacheTimestamp);
      if (cacheTimestampDiffInHours <= CACHE_TTL_HOURS) {
        const data = await this.db.getAllData(DB_INFO.keys, DB_INFO.objectStoreName);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (data.user && data.extensions) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          return { user: data.user, extensions: data.extensions };
        }
      }
    }
    const newSettings = await readNewSettings();

    const user = VBCUserService.normalizeUser(newSettings);
    const extensions = VBCUserService.normalizeExtensions(newSettings);

    if (this.useCache) {
      localStorage.setItem(CACHE_TIMESTAMP_KEY, new Date().getTime().toString());
      this.db.saveStore({ user, extensions }, DB_INFO.objectStoreName);
    }

    return { user, extensions };
  }

  public async destroy() {
    if (this.useCache) {
      localStorage.removeItem(CACHE_TIMESTAMP_KEY);
      await this.db.delete();
    }
  }

  private static normalizeExtensions(newSettings): Array<VBCExtension> {
    return newSettings.extensions.map(extension => {
      return {
        id: extension.externalId,
        extension: extension.extension,
        didList: extension.didList,
        isDefault: extension.isDefault,
        isVirtual: extension.isVirtual,
        locale: extension.locale,
        kitId: extension.kitId
      };
    });
  }

  private static normalizeUser(newSettings): VBCUser {
    const basicInfo = newSettings.basicInfo;
    const contactInfo = newSettings.contactInfo;
    return {
      id: basicInfo.userId.toString(),
      accountId: basicInfo.accountId.toString(),
      username: basicInfo.userName,
      firstName: basicInfo.firstName,
      lastName: basicInfo.lastName,
      email: contactInfo.email,
      phoneNumber: contactInfo.phoneNumber,
      role: basicInfo.roles[0],
      locale: newSettings.locale
    };
  }
}
